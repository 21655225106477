import React, { useContext } from 'react';
import PortableText from 'react-portable-text';

import {
	Grid,
	Typography,
	Button,
	useMediaQuery,
	makeStyles,
} from '@material-ui/core';

import { FeatureContent } from '../../General/Feature/FeatureContent';

import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		fontSize: '1.25rem',
		paddingTop: '1em',
    [theme.breakpoints.down('md')]: {
      paddingTop: '3em',
    },
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.125rem',
		},
	},
  header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		fontSize: '2.0243rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.8219rem',
      paddingBottom: '3rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5625rem',
		},
	},
	subHeader: {
		fontWeight: 400,
		color: theme.workwaveBlue,
		fontSize: '1.25rem',
	},
	playerWrapper: {
		position: 'relative',
		paddingTop: '56.25%',
		minHeight: '100%',
		minWidth: '100%',
		border: '1px solid #d8dee0',
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	img: {
		maxWidth: '580px',
		border: '1px solid #d8dee0',
		borderRadius: '8px',
		// maxWidth: '600px',
		// [theme.breakpoints.up('sm')]: {
		// 	maxWidth: '504px',
		// },
		// [theme.breakpoints.up('xs')]: {
		// 	maxWidth: '295px',
		// },
	},
	button: {
		padding: '16px 32px',
    marginTop: '2rem',
		// fontSize: '20px',
		transition: 'all 0.35s ease !important',
		// '&:hover': {
		// 	opacity: '0.85 !important',
		// },
		[theme.breakpoints.down('md')]: {
		  padding: '12px 32px',
      marginBottom: '3rem',
		},
		// [theme.breakpoints.down('sm')]: {
		//   padding: '8px 22px',
		//   fontSize: '0.9375rem',
		// },
		[theme.breakpoints.down('xs')]: {
      padding: '8px 24px',
		},
	},
	logoSection: {
		marginTop: '3.125rem',
	},
	logo: {
		width: '5rem',
	},
	logoText: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		textAlign: 'center',
	},
}));

export const RmSpecialSection = ({
	feature,
	accentColor,
	zapierLogo,
	shopifyLogo,

}) => {
	const classes = useStyles();
	const { bodyHeader, ctaText, _rawBodyCopy, image, } =
		feature;
	const { handleModalClick } = useContext(FormModalContext);

	// const lg = useMediaQuery('(max-width: 1280px)');
  const med = useMediaQuery('(max-width: 960px)');
	return (
		<>
			<Grid 
        container 
        direction='row' 
        justifyContent='space-between'
			  alignItems='center'
        spacing={4}>
        {!med && (
          <FeatureContent
            image={image}
          />
        )}
				{/* <Grid item xs={12}>
					<Typography variant='h5' className={classes.subHeader}>
						{bodySubHeader}
					</Typography>
				</Grid> */}
				<Grid 
          container
          item 
          xs={12} 
          md={6}
          direction='column'
				  justifyContent='flex-start'
				  alignItems='flex-start'>
          <Typography variant='h2' className={classes.header}>
						{bodyHeader}
					</Typography>

          {// On mobile we want the image to display in between the header and the bullets
          med && (
            <FeatureContent
              image={image}
            />
          )}

					{_rawBodyCopy.map((content, index) => (
						<PortableText
							key={index}
							content={content}
							// className={classes.text}
							serializers={{
								// h2: ({ children }) => (
								// 	<Typography
								// 		variant='h2'
								// 		className={classes.header}>
								// 		{children}
								// 	</Typography>
								// ),
								normal: ({ children }) => (
									<Typography
										variant='body1'
										className={classes.text}>
										{children}
									</Typography>
								),
								li: ({ children }) => (
									<Grid item>
										<Typography
											variant='body1'
											className={classes.text}>
											<li>{children}</li>
										</Typography>
									</Grid>
								),
							}}
						/>
					))}
          <Grid
            container
            direction='row'>
            <Button
              variant='contained'
              size='large'
              className={classes.button}
              onClick={handleModalClick}
              style={{
                background: accentColor,
                color: 'white',
              }}>
              {ctaText}
            </Button>
          </Grid>
				</Grid>
			</Grid>
			{/* <Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				style={{ padding: '2rem 0' }}
				className={classes.logoSection}>
				<Grid item xs={12} style={{ paddingBottom: '2rem' }}>
					<Typography variant='h5' className={classes.header}>
						{logoHeader}
					</Typography>
				</Grid>
				<Grid
					item
					container
					direction='row'
					justifyContent='center'
					alignItems='center'> */}
					{/* <Grid
						item
						container
						style={{ marginBottom: xs && '1rem' }}
						direction='column'
						alignItems='center'
						xs={12}
						sm={2}>
						<div
							style={{
								height: '6rem',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							<GatsbyImage
								image={shopifyLogo.node.appLogo.asset.gatsbyImageData}
								className={classes.logo}
							/>
						</div>

						<Grid
							item
							container
							direction='row'
							justifyContent='center'
							alignItems='flex-end'>
							<Typography variant='h5' className={classes.logoText}>
								{shopifyLogo.node.appLogoTitle}
							</Typography>
						</Grid>
					</Grid> */}
					{/* <Grid
						item
						container
						direction='column'
						alignItems='center'
						xs={12}
						sm={2}>
						<div
							style={{
								height: '6rem',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							<GatsbyImage
								image={zapierLogo.node.appLogo.asset.gatsbyImageData}
								className={classes.logo}
							/>
						</div>

						<Grid
							item
							container
							direction='row'
							justifyContent='center'
							alignItems='flex-end'>
							<Typography variant='h5' className={classes.logoText}>
								{zapierLogo.node.appLogoTitle}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid> */}

		</>
	);
};
